<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="filterReserves"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <!-- headers -->
            <template v-slot:[`header.number`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="numberToFilter"
                    dense
                    style="font-size: 12px !important; width: 80px"
                />
            </template>
            <template v-slot:[`header.date`]="{ header }">
                <div class="d-flex justify-center">
                    <p class="text-uppercase pt-4 mr-1">{{ header.text }}</p>
                    <v-btn
                        small
                        icon
                        v-if="activateArrow"
                        class="mt-3"
                        @click="sort('asc')"
                    >
                        <v-icon size="14">mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn small icon v-else class="mt-3" @click="sort('desc')">
                        <v-icon size="14">mdi-arrow-down</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`header.createdBy`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="userToFilter"
                    dense
                    class="pt-2"
                    style="font-size: 14px; width: 120px"
                />
            </template>
            <template v-slot:[`header.status`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="statusToFilter"
                    dense
                    class="pt-2"
                    style="font-size: 14px"
                />
            </template>
            <template v-slot:[`header.description`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="descriptionToFilter"
                    dense
                    class="pt-2"
                    style="font-size: 14px; width: 125px"
                />
            </template>
            <template v-slot:[`header.notes`]="{ header }">
                <p class="pt-4" style="width: 150px">{{ header.text }}</p>
            </template>
            <!--Items--->
            <template v-slot:[`item.date`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="px-0 py-0 my-0">
                        {{
                            item.updatedOn
                                ? formatDate(item.updatedOn)
                                : formatDate(item.createdOn)
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.type`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="px-0 py-0 my-0 text-capitalize">
                        {{ item.type }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.price`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="px-0 py-0 my-0">
                        {{ formatNumber(item.price) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.total`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="px-0 py-0 my-0">
                        {{ formatNumber(item.total) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex justify-center">
                    <v-chip :color="colors[item.status]" dark>
                        <span
                            class="mt-0 text-body-1 text-capitalize"
                            style="text-align: center !important"
                            >{{
                                item.status == 'onHold'
                                    ? 'On Hold'
                                    : item.status
                            }}</span
                        >
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div
                    v-if="
                        (projectId && currentUser == item.userId) ||
                            approveStock
                    "
                    class="d-flex justify-center"
                >
                    <v-btn
                        v-if="approveStock || item.status == 'onHold'"
                        small
                        icon
                        @click="openForm(item)"
                    >
                        <v-icon v-if="item.status != 'onHold'">mdi-eye</v-icon>
                        <v-icon v-else>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        small
                        icon
                        @click="openDialogReserve(item)"
                        v-if="approveStock || item.status == 'approved'"
                    >
                        <v-icon>mdi-database-arrow-left-outline</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="item.status == 'onHold'"
                        small
                        icon
                        @click="openDialogDelete(item)"
                    >
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!--Release Reserve-->
        <v-dialog
            :retain-focus="false"
            v-model="openReserve"
            persistent
            :width="$vuetify.breakpoint.mobile ? '80%' : '40%'"
        >
            <ReleaseReserve
                v-if="openReserve"
                :item="selectedItem"
                :projects="projects"
                @closeDialog="closeDialogReserve"
                @release="release"
            />
        </v-dialog>
        <!--Reserve Form-->
        <v-dialog
            :retain-focus="false"
            v-model="openReserveForm"
            persistent
            :width="$vuetify.breakpoint.mobile ? '80%' : '40%'"
        >
            <ReserveForm
                v-if="openReserveForm"
                :reserve="selectedItem"
                :originalReserve="originalItem"
                :approveStock="approveStock"
                @closeDialog="closeForm"
                @edit="replaceReserve"
            />
        </v-dialog>
        <!--Reserve Delete-->
        <v-dialog
            :retain-focus="false"
            v-model="openReserveDelete"
            persistent
            :width="$vuetify.breakpoint.mobile ? '80%' : '40%'"
        >
            <ReserveDelete
                v-if="openReserveDelete"
                :item="selectedItem"
                @closeDialog="closeDialogDelete"
                @deleteReserve="deleteReserve"
            />
        </v-dialog>

        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script>
import _ from 'lodash'
import ReleaseReserve from '@/components/StockRoom/ReleaseReserve.vue'
import ReserveForm from '@/components/StockRoom/ReserveForm.vue'
import ReserveDelete from '@/components/StockRoom/ReserveDelete.vue'
import API from '@/services/api'
import { mapMutations } from 'vuex'
import moment from 'moment'

export default {
    name: 'Reserves',
    props: {
        projectId: {
            type: String,
            default: () => undefined,
        },
    },
    components: {
        ReleaseReserve,
        ReserveForm,
        ReserveDelete,
    },
    data: () => ({
        activateArrow: true,
        activateAlert: false,
        openReserveDelete: false,
        openReserveForm: false,
        loading: false,
        openReserve: false,
        selectedItem: {},
        headers: [
            {
                text: 'S/N',
                value: 'number',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DATE',
                value: 'date',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'FROM',
                value: 'item.project_name',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TO',
                value: 'projectName',
                align: 'center',
                sortable: false,
            },
            {
                text: 'STORAGE',
                value: 'item.storage',
                align: 'center',
                sortable: false,
            },
            {
                text: 'STATUS',
                value: 'status',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        reserves: [],
        search: '',
        colors: {
            onHold: '#EDC43B',
            approved: '#27BC4F',
            rejected: '#FF5252',
        },
        alertMessage: '',
        originalItem: {},
        approveStock: undefined,
        statusToFilter: undefined,
        numberToFilter: undefined,
        users: [],
        userToFilter: undefined,
        descriptionToFilter: undefined,
        currentUser: undefined,
    }),
    async mounted() {
        try {
            this.loading = true
            if (this.projectId) {
                this.reserves = await API.getReserves(this.projectId)
            } else {
                this.reserves = await API.getReserves()
            }
            this.projects = await API.getLiteProjects({})
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            this.reserves.forEach(reserve => {
                const user = this.users.find(user => user.id == reserve.userId)
                if (user) {
                    reserve.createdBy = user.name
                }
            })
            const {
                data: { user },
            } = await API.getMyInfo()
            this.approveStock = user.permissions.find(p => p == 'approveStock')
            this.currentUser = user.id
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    computed: {
        filterReserves() {
            let conditions = []

            if (this.statusToFilter) {
                conditions.push(this.filterForStatus)
            }

            if (this.numberToFilter) {
                conditions.push(this.filterForNumber)
            }

            if (this.userToFilter) {
                conditions.push(this.filterForUser)
            }

            if (this.descriptionToFilter) {
                conditions.push(this.filterForDescription)
            }

            if (conditions.length > 0) {
                return this.reserves.filter(reserve => {
                    return conditions.every(condition => {
                        return condition(reserve)
                    })
                })
            }
            return this.reserves
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        filterForDescription(item) {
            return (
                item.description &&
                item.description
                    .toLowerCase()
                    .includes(this.descriptionToFilter.toLowerCase())
            )
        },
        filterForUser(item) {
            return (
                item.createdBy &&
                item.createdBy
                    .toLowerCase()
                    .includes(this.userToFilter.toLowerCase())
            )
        },
        filterForStatus(item) {
            return (
                item.status &&
                item.status
                    .toLowerCase()
                    .includes(this.statusToFilter.toLowerCase())
            )
        },
        filterForNumber(item) {
            return (
                item.number &&
                item.number.toString().includes(this.numberToFilter)
            )
        },
        formatNumber(value) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
            }).format(Math.ceil(value))
        },
        sort(direction) {
            if (direction == 'asc') {
                this.activateArrow = false
                this.reserves.sort(
                    (a, b) =>
                        (a.updatedOn
                            ? a.updatedOn._seconds || a.updatedOn.seconds
                            : a.createdOn._seconds || a.createdOn.seconds) -
                        (b.updatedOn
                            ? b.updatedOn._seconds || b.updatedOn.seconds
                            : b.createdOn._seconds || b.createdOn.seconds)
                )
                return null
            } else if (direction == 'desc') {
                this.activateArrow = true
                this.reserves.sort(
                    (a, b) =>
                        (b.updatedOn
                            ? b.updatedOn._seconds || b.updatedOn.seconds
                            : b.createdOn._seconds || b.createdOn.seconds) -
                        (a.updatedOn
                            ? a.updatedOn._seconds || a.updatedOn.seconds
                            : a.createdOn._seconds || a.createdOn.seconds)
                )
                return null
            }
        },
        formatDate(date) {
            return moment
                .unix(date._seconds || date.seconds)
                .format('YYYY-MM-DD')
        },
        addReserve(reserve) {
            this.reserves.splice(0, 0, reserve)
        },
        replaceReserve(reserve) {
            const index = this.reserves.findIndex(r => r.id == reserve.id)
            if (index > -1) {
                this.reserves.splice(index, 1, {
                    ...this.reserves[index],
                    ...reserve,
                })
            }
            this.closeForm()
            this.alertMessage = 'The reserve was successfully changed'
            this.activateAlert = true
        },
        deleteReserve(id) {
            const index = this.reserves.findIndex(r => r.id == id)
            if (index > -1) {
                this.reserves.splice(index, 1)
            }
            this.closeDialogDelete()
            this.alertMessage = 'Reserve was successfully trashed'
            this.activateAlert = true
        },
        async release(release) {
            try {
                this.loading = true
                const newRelease = await API.releaseReserve({
                    reserveId: this.selectedItem.id,
                    projectId: this.selectedItem.projectId,
                    targetProjectId: release.projectId,
                    quantity: release.quantity,
                })
                this.addReserve(newRelease)
                this.closeDialogReserve()
                this.alertMessage =
                    'The creation of the release has been successful'
                this.activateAlert = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openDialogDelete(item) {
            this.openReserveDelete = true
            this.selectedItem = _.cloneDeep(item)
        },
        closeDialogDelete() {
            this.openReserveDelete = false
            this.selectedItem = {}
        },
        openForm(item) {
            this.openReserveForm = true
            this.selectedItem = _.cloneDeep(item)
            this.originalItem = _.cloneDeep(item)
        },
        closeForm() {
            this.openReserveForm = false
            this.selectedItem = {}
            this.originalItem = {}
        },
        openDialogReserve(item) {
            this.openReserve = true
            this.selectedItem = _.cloneDeep(item)
        },
        closeDialogReserve() {
            this.openReserve = false
            this.selectedItem = {}
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
.v-input .v-label {
    font-size: 12px;
}
</style>
